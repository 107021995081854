import type { NextPage } from 'next'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const NotFound: NextPage = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace({
      pathname: '/',
      query: {
        radius: 50,
        address: '10178 Berlin, Deutschland',
        lat: 52.521749,
        lng: 13.397671,
        hourlyRate: 0,
        hoursMax: 48,
        hoursMin: 1,
        jobTypes: [
          'parttimejob',
          'minijob',
          'temporaryjob',
          'weekendjob',
          'internship',
          'studentjob',
        ].join(','),
        jobCategories: [
          'fastfood',
          'restaurant',
          'bar',
          'cafe',
          'fashion',
          'groceries',
          'logistik',
          'others',
          'service',
        ].join(','),
      },
    })
  }, [router])

  return <div />
}

export default NotFound
